import React from 'react'
import Layout from '../../components/layout'
import PolicyDetails from '../../components/terms/terms-of-service'
import SEO from '../../components/seo'
import TermsGrid from '../../components/terms/terms-grid'

const TermsOfService = ({ location }) => {
  return (
    <Layout>
      <SEO title='Terms of Service' pageType='home' flowType='none' />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default TermsOfService
