import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: 0 auto;
  padding: ${theme.space[5]}px;
  display: flex;
  flex-direction: column;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  color: ${theme.colors.blackhighlight};
`

Policy.titleDash = styled.span`
  font-size: ${theme.font['3xl']};
  border-top: 1px solid ${theme.colors.lightGray};
  margin: ${theme.space[12]}px 0;
`

Policy.subtitle = styled.span`
  padding-bottom: ${theme.space[4]}px;
  padding-top: ${theme.space[8]}px;
  font-size: ${theme.font['xl']};
`

Policy.listChildSubtitle = styled.li`
  padding: ${theme.space[2]}px 0;
  margin-top: ${theme.space[2]}px;
  font-size: ${theme.font['2xl']};
  color: ${theme.colors.font.lightgray};
`

const determineListStyle = (props) => {
  if (props.isCircle) {
    return 'circle'
  } else if (props.isSquare) {
    return 'square'
  } else {
    return 'disc'
  }
}

Policy.list = styled.ol`
  list-style-type: ${(props) => determineListStyle(props)};
  padding-left: ${theme.space[8]}px;
`

Policy.listChild = styled.li`
  color: ${theme.colors.font.lightgray};
  padding: ${theme.space[2]}px 0;
`

Policy.paragraph = styled.span`
  font-size: ${theme.font.base};
  color: ${theme.colors.font.lightgray};
  padding: ${theme.space[4]}px 0;
`

Policy.notice = styled.span`
  font-size: ${theme.font.base};
  color: ${theme.colors.gray};
  font-style: italic;
  font-weight: 600;
  padding-bottom: ${theme.space[6]}px;
`

Policy.link = styled(Link)`
  color: ${theme.colors.blue};
  font-size: ${theme.font.base};
  text-decoration: none;
  display: contents;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.tealhighlight};
  }
`

Policy.linkContainer = styled.div`
  padding-top: ${theme.space[2]}px;
  display: flex;
  flex-direction: column;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.blue};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.tealhighlight};
  }
`

Policy.singleParagraph = styled.div`
  padding: ${theme.space[4]}px 0;
  color: ${theme.colors.font.lightgray};
`

Policy.boldSentence = styled.span`
  font-weight: 600;
`

Policy.italicSentence = styled.span`
  font-style: italic;
`

Policy.updatedText = styled.span`
  margin-top: ${theme.space[6]}px;
  font-size: ${theme.font['sm']};
  color: ${theme.colors.font.lightgray};
`

export default Policy
